<footer class="footer" [preRender]="false" (deferLoad)="showFooterImages=true">
  <div class="b-container">
    <div class="links">
      <div class="footer__logo">
        @if (showFooterImages) {
          <img src="/assets/images/logo-large--white.svg" alt="logo" routerLink="/">
        }
      </div>

      <div class="footer__links-wrapper">
        <div class="footer__links-group">
          <h4>About</h4>
          <ul>
            <li class="footer__link" data-cy="footer__about-link_desktop">
              <a routerLink="/about">Our Team</a>
            </li>

            <li class="footer__link" data-cy="footer__partners-link_desktop">
              <a routerLink="/collaborators">Our Partners</a>
            </li>

            <li class="footer__link" data-cy="footer__upload-mri-link_desktop">
              <a routerLink="/upload-mri">Upload Your MRI Scan</a>
            </li>

            <li class="footer__link" data-cy="footer__3d-print-link_desktop">
              <a routerLink="/print-your-brain">3D Print Your Brain</a>
            </li>

            @if (isAuthenticated$ | async) {
              @if (isVerified$ | async) {
                <li class="footer__link">
                  <a class="bold-text" (click)="gs.navigate()">My Dashboard</a>
                </li>
              } @else {
                <li class="footer__link">
                  <a class="bold-text" routerLink="/auth/logout">Log Out</a>
                </li>
                <li class="footer__link">
                  <a class="bold-text" (click)="gs.navigate()">Get Started</a>
                </li>
              }
            } @else {
              <li class="footer__link">
                <a class="bold-text" routerLink="/auth/sign-in">Log In</a>
              </li>
              <li class="footer__link">
                <a class="bold-text" (click)="gs.navigate()">Get Started</a>
              </li>
            }

          </ul>
        </div>

        <div class="footer__links-group">
          <h4>Learn</h4>
          <ul>
            <li class="footer__link" data-cy="footer__how-it-works-link_desktop">
              <a routerLink="/how-it-works">How It Works</a>
            </li>

            <li class="footer__link" data-cy="footer__pricing-link_desktop">
              <a routerLink="/pricing">Pricing</a>
            </li>

            <li class="footer__link" data-cy="footer__tutorials-link_desktop">
              <a routerLink="/tutorial">Tutorials</a>
            </li>

            <li class="footer__link" data-cy="footer__blog-link_desktop">
              <a routerLink="/blog">Blog</a>
            </li>

            <li class="footer__link" data-cy="footer__rollerblade-link_desktop">
              <a routerLink="/rollerblade">Rollerblade</a>
            </li>
          </ul>
        </div>

        <div class="footer__links-group">
          <h4>Support</h4>
          <ul>
            <li class="footer__link" data-cy="footer__faq-link_desktop">
              <a routerLink="/faq">FAQ</a>
            </li>

            <li class="footer__link" data-cy="footer__contact-us-link_desktop">
              <a routerLink="/contact">Contact Us</a>
            </li>

            <li class="footer__link" data-cy="footer__for-physicians-link_desktop">
              <a routerLink="/physicians">For Physicians</a>
            </li>
          </ul>
        </div>

        <div class="footer__links-group">
          <h4>Legal</h4>
          <ul>
            <li class="footer__link" data-cy="footer__privacy-policy-link_desktop">
              <a routerLink="/privacy">Privacy Policy</a>
            </li>

            <li class="footer__link" data-cy="footer__hipaa_desktop">
              <a routerLink="/privacy">HIPAA</a>
            </li>

            <li class="footer__link" data-cy="footer__terms-of-service-link_desktop">
              <a routerLink="/terms-of-service">Terms of Service</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- For small screens -->
    <div class="accordion-menu">
      <div class="accordion-menu__item">
        <app-accordion title="About" data-cy="footer__about-accordion_mobile">
          <div class="accordion-menu__content">
            <ul>
              <li>
                <a routerLink="/" data-cy="about-accordion__home-link_mobile">Home</a>
              </li>
              <li>
                <a routerLink="/about" data-cy="about-accordion__about-link_mobile">Our Team</a>
              </li>
              <li>
                <a routerLink="/collaborators" data-cy="about-accordion__collaborators-link_mobile">Our Partners</a>
              </li>
              <li>
                <a routerLink="/upload-mri" data-cy="about-accordion__upload-mri-link_mobile">Upload Your MRI Scan</a>
              </li>
              <li>
                <a routerLink="/schedule-mri" data-cy="about-accordion__schedule-mri-link_mobile">Get A BrainKey MRI Scan</a>
              </li>
              <li>
                <a routerLink="/print-your-brain" data-cy="about-accordion__print-your-brain-link_mobile">3D Print Your Brain</a>
              </li>

              @if (isAuthenticated$ | async) {
                @if (isVerified$ | async) {
                  <li>
                    <a class="bold-text" (click)="gs.navigate()">My Dashboard</a>
                  </li>
                } @else {
                  <li>
                    <a class="bold-text" routerLink="/auth/logout">Log Out</a>
                  </li>
                  <li>
                    <a class="bold-text" (click)="gs.navigate()">Get Started</a>
                  </li>
                }
              } @else {
                <li>
                  <a class="bold-text" routerLink="/auth/sign-in">Log In</a>
                </li>
                <li>
                  <a class="bold-text" (click)="gs.navigate()">Get Started</a>
                </li>
              }

            </ul>
          </div>
        </app-accordion>
      </div>

      <div class="accordion-menu__item">
        <app-accordion title="Learn" data-cy="footer__learn-accordion_mobile">
          <div class="accordion-menu__content">
            <ul>
              <li>
                <a routerLink="/how-it-works" data-cy="about-accordion__how-it-works-link_mobile">How It Works</a>
              </li>

              <li>
                <a routerLink="/pricing" data-cy="about-accordion__pricing-link_mobile">Pricing</a>
              </li>

              <li>
                <a routerLink="/tutorial" data-cy="about-accordion__tutorials-link_mobile">Tutorials</a>
              </li>

              <li>
                <a routerLink="/blog" data-cy="about-accordion__blog-link_mobile">Blog</a>
              </li>

              <li>
                <a routerLink="/rollerblade" data-cy="about-accordion__rollerblade-link_mobile">Rollerblade</a>
              </li>
            </ul>
          </div>
        </app-accordion>
      </div>

      <div class="accordion-menu__item">
        <app-accordion title="Support" data-cy="footer__support-accordion_mobile">
          <div class="accordion-menu__content">
            <ul>
              <li>
                <a routerLink="/faq" data-cy="about-accordion__faq-link_mobile">FAQ</a>
              </li>
              <li>
                <a routerLink="/contact" data-cy="about-accordion__contact-link_mobile">Contact Us</a>
              </li>
              <li>
                <a routerLink="/physicians" data-cy="about-accordion__physicians-link_mobile">For Physicians</a>
              </li>
            </ul>
          </div>
        </app-accordion>
      </div>

      <div class="accordion-menu__item">
        <app-accordion title="Legal" data-cy="footer__legal-accordion_mobile">
          <div class="accordion-menu__content">
            <ul>
              <li>
                <a routerLink="/privacy" data-cy="about-accordion__privacy-link_mobile">Privacy Policy</a>
              </li>
              <li>
                <a routerLink="/privacy" data-cy="about-accordion__hipaa-link_mobile">HIPAA</a>
              </li>
              <li>
                <a routerLink="/terms-of-service" data-cy="about-accordion__terms-of-service-link_mobile">Terms of Service</a>
              </li>
            </ul>
          </div>
        </app-accordion>
      </div>
    </div>

    <div class="footer__bottom">
      <div class="copyright">
        <p>
          Copyright &copy; {{ currentYear }} Brain Key Inc. All Rights Reserved.
        </p>
        <p>
          Disclaimer: Any information provided online at BrainKey.ai or elsewhere by Brain Key is for educational and informational
          use only. The information is not intended to be used for any diagnostic purpose and is never a substitute for professional
          medical advice.
        </p>
      </div>
      <div class="socials">
        @if (showFooterImages) {
          <div class="social-icon">
            <a href="https://www.linkedin.com/company/brainkeyai/" data-cy="footer__linkedin-link_mobile">
              <img src="/assets/images/socials/linkedin-icon--white.svg" alt="linkedin">
            </a>
          </div>
          <div class="social-icon">
            <a href="https://www.facebook.com/BrainKeyAI" data-cy="footer__facebook-link_mobile">
              <img src="/assets/images/socials/facebook-icon--white.svg" alt="facebook">
            </a>
          </div>
          <div class="social-icon">
            <a href="https://twitter.com/BrainkeyAI" data-cy="footer__twitter-link_mobile">
              <img src="/assets/images/socials/twitter-icon--white.svg" alt="twitter">
            </a>
          </div>
          <div class="social-icon">
            <a href="https://www.instagram.com/brainkeyai/" data-cy="footer__instagram-link_mobile">
              <img src="/assets/images/socials/instagram-icon--white.svg" alt="instagram">
            </a>
          </div>
        }
      </div>
    </div>

  </div>
</footer>
